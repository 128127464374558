<template>
	<div class="p-grid crud-demo" :key="componentKey">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="New" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" @click="openNew" />
						<Button label="Delete" icon="pi pi-trash" class="p-button-danger p-mb-2" @click="confirmDeleteSelected" :disabled="!selectedProductTypes || !selectedProductTypes.length" />
					</template>
                </Toolbar>
				<DataTable ref="dt" :value="productTypes" class="p-datatable-responsive-demo" v-model:selection="selectedProductTypes" 
					dataKey="id" :paginator="true" :rows="10" :filters="filters" :loading="loading1"
					paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" 
					:rowsPerPageOptions="[5,10,25]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} product types">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Manage Product Types</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Search..." />
                            </span>
						</div>
					</template>
					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="id" header="ID" :sortable="true" headerStyle="width:45%">
					</Column>
					<Column field="orig_name" header="Name" :sortable="true" headerStyle="width:45%">
					</Column>
					<Column field="name_ru" header="Name RU" :sortable="true" headerStyle="width:45%">
					</Column>
					<Column field="name_uz" header="Name UZ" :sortable="true" headerStyle="width:45%">
					</Column>
					<Column headerStyle="width: 10%">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProductType(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProductType(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productTypeDialog" :style="{width: '400px'}" header="Product Type Details" :modal="true" class="p-fluid">
					<div class="p-formgrid p-grid">
						<div class="p-field p-col-6">
							<label for="name">Name</label>
							<InputText id="name" v-model.trim="productType.orig_name" required="true" autofocus :class="{'p-invalid': submitted && !productType.orig_name}" />
							<small class="p-invalid" v-if="submitted && !productType.orig_name">Name is required.</small>
						</div>
						<div class="p-field p-col-6">
							<label for="name">Name RU</label>
							<InputText id="name" v-model.trim="productType.name_ru" required="true" autofocus :class="{'p-invalid': submitted && !productType.name_ru}" />
							<small class="p-invalid" v-if="submitted && !productType.name_ru">Name is required.</small>
						</div>
						<div class="p-field p-col-6">
							<label for="name">Name UZ</label>
							<InputText id="name" v-model.trim="productType.name_uz" required="true" autofocus :class="{'p-invalid': submitted && !productType.name_uz}" />
							<small class="p-invalid" v-if="submitted && !productType.name_uz">Name is required.</small>
						</div>
					</div>
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveProductType" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductTypeDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="productType">Are you sure you want to delete <b>{{productType.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductTypeDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProductType" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductTypesDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="productType">Are you sure you want to delete the selected product types?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductTypesDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProductTypes" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import ProductTypeService from '../service/ProductTypeService';

export default {
	data() {
		return {
			productTypes: null,
			productTypeDialog: false,
			deleteProductTypeDialog: false,
			deleteProductTypesDialog: false,
			productType: {},
			selectedProductTypes: null,
			filters: {},
			submitted: false,
			loading1: true,
			componentKey: 0,

		}
	},
	productTypeService: null,
	created() {
		this.productTypeService = new ProductTypeService();
	},
	mounted() {
		this.onMounted()
	},
	updated() {
		// this.onMounted()
	},
	methods: {
		onMounted() {
			this.productTypeService.getProductTypes().then(data => {
				if (data.isLogged){
					this.productTypes = data.fetch_data;
					this.loading1 = false
				} else {
					this.$router.push('Login')
				}
			})
		},
		
		openNew() {
			this.productType = {};
			this.submitted = false;
			this.productTypeDialog = true;
		},
		
		hideDialog() {
			this.productTypeDialog = false;
			this.submitted = false;
            console.log(this.productType);
		},
		
		saveProductType() {
			if (this.productType.id) {
				this.productTypeService.updateProductType(this.productType)
					.then(response => {
						this.productTypeDialog = false;
						return response
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.then(response => {
						this.onMounted()
						return response 
					})
					.catch(error => {
						console.log(error)
					})
			} else {
				this.productTypeService.insertNewProductType(this.productType)
					.then(response => {
						this.productTypeDialog = false;
						return response
					})
					.then(response => {
						this.onMounted()
						return response 
					})
					.then(response => {
						this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
						return response
					})
					.catch(error => {
						console.log(error)
					})
			}
		
		},
		
		editProductType(productType) {
			this.productType = {...productType};
			this.productTypeDialog = true;
		},
		
		confirmDeleteProductType(productType) {
			this.productType = productType;
			this.deleteProductTypeDialog = true;
		},
		
		deleteProductType() {
			this.productTypeService.deleteProductType(this.productType)
				.then(response => {
					console.log(response)
					return response
				})
				.then(response => {					
					this.deleteProductTypeDialog = false;
					return response
				})
				.then(response => {
					this.$toast.add({severity:'success', summary: 'Successful', detail: response.data.fetch_data, life: 3000});
					return response
				})
				.then(response => {
					this.onMounted()
					return response
				}) 
				.catch(error => {
					console.log(error)
				})
		},
		
		confirmDeleteSelected() {
			this.deleteProductTypesDialog = true;
		},
		
		deleteSelectedProductTypes() {
			this.productTypes = this.productTypes.filter(val => !this.selectedProductTypes.includes(val));
			this.deleteProductTypesDialog = false;
			this.selectedProductTypes = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Product Types Deleted', life: 3000});
		}
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.producttype-image-modal {
	width: 100%;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	margin: 1rem 0;
	display: block;
}
.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-published {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-saved {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
